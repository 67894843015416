import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import Web3 from 'web3';

let web3;

function App() {
    const [twitterUser, setTwitterUser] = useState(null);
    const [discordUser, setDiscordUser] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const twitter_id = Cookies.get('twitter_id');
        const twitter_token = Cookies.get('twitter_token');

        if (twitter_id && twitter_token) {
            axios.get(`${API_URL}/twitter_user/${twitter_id}`, {
                headers: {
                    Authorization: `Bearer ${twitter_token}`,
                },
            })
                .then(response => {
                    setTwitterUser(response.data[0]);
                })
                .catch(error => {
                    console.error('Error fetching twitterUser data:', error);
                    if (error.response && error.response.status === 401) {
                        // handle unauthorized error
                    }
                });
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const newToken = urlParams.get('twitter_token');
            if (newToken) {
                const decodedToken = jwtDecode(newToken);
                const newId = decodedToken.id;
                Cookies.set('twitter_id', newId, {expires: 30, SameSite: 'Strict'});
                Cookies.set('twitter_token', newToken, {expires: 30, SameSite: 'Strict'});
                axios.get(`${API_URL}/twitter_user/${newId}`, {
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                    },
                })
                    .then(response => {
                        setTwitterUser(response.data[0]);
                    })
                    .catch(error => {
                        console.error('Error fetching twitterUser data:', error);
                        if (error.response && error.response.status === 401) {
                            // handle unauthorized error
                        }
                    });
            }
        }

        const discord_id = Cookies.get('discord_id');
        const discord_token = Cookies.get('discord_token');

        if (discord_id && discord_token) {
            axios.get(`${API_URL}/discord_user/${discord_id}`, {
                headers: {
                    Authorization: `Bearer ${discord_token}`,
                },
            })
                .then(response => {
                    setDiscordUser(response.data);
                })
                .catch(error => {
                    console.error('Error fetching discordUser data:', error);
                    if (error.response && error.response.status === 401) {
                        // handle unauthorized error
                    }
                });
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const newToken = urlParams.get('discord_token');
            if (newToken) {
                const decodedToken = jwtDecode(newToken);
                const newId = decodedToken.id;
                Cookies.set('discord_id', newId, {expires: 30, SameSite: 'Strict'});
                Cookies.set('discord_token', newToken, {expires: 30, SameSite: 'Strict'});
                axios.get(`${API_URL}/discord_user/${newId}`, {
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                    },
                })
                    .then(response => {
                        setDiscordUser(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching discordUser data:', error);
                        if (error.response && error.response.status === 401) {
                            // handle unauthorized error
                        }
                    });
            }
        }

    }, []);

    const handleTwitterLogin = () => {
        window.open(`${API_URL}/auth/twitter`, '_blank');
    };

    const handleDiscordLogin = () => {
        window.open(`${API_URL}/auth/discord`, '_blank');
    }
    const handleTwitterPost = () => {
        const tweetText = "@Roguex_io  post.";
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, '_blank');
    };

    const handleAuthenticate = ({
                                    publicAddress, signature, timeSeconds
                                }) => fetch(`${API_URL}/auth/wallet`, {
        body: JSON.stringify({publicAddress, signature, timeSeconds}), headers: {
            'Content-Type': 'application/json',
        }, method: 'POST',
    }).then((response) => response.json());
    const handleSignMessage = async ({
                                         publicAddress,
                                     }) => {
        try {
            let timeSeconds = Math.round(Date.now() / 1000);
            const signature = await web3.eth.personal.sign(`${timeSeconds}`, publicAddress, '' // MetaMask will ignore the password argument here
            );

            return {publicAddress, signature, timeSeconds};
        } catch (err) {
            throw new Error('You need to sign the message to be able to log in.');
        }
    };

    const handleWalletClick = async () => {
        // Check if MetaMask is installed
        if (!window.ethereum) {
            window.alert('Please install MetaMask first.');
            return;
        }

        if (!web3) {
            try {
                // Request account access if needed
                await window.ethereum.enable();

                // We don't know window.web3 version, so we use our own instance of Web3
                // with the injected provider given by MetaMask
                web3 = new Web3(window.ethereum);
            } catch (error) {
                window.alert('You need to allow MetaMask.');
                return;
            }
        }

        const coinbase = await web3.eth.getCoinbase();
        if (!coinbase) {
            window.alert('Please activate MetaMask first.');
            return;
        }

        const publicAddress = coinbase.toLowerCase();
        console.log("publicAddress::", publicAddress);
        setLoading(true);

        // let signedData = await handleSignMessage({publicAddress});
        // console.log("signedData::", signedData);

        handleSignMessage({publicAddress})
            .then(handleAuthenticate)
            .then((response) => {
                console.log("response::", response);
                setWallet(response);
            })


    };

    return (<div className="App">
        <button onClick={handleTwitterPost}>Post to Twitter</button>
        {twitterUser ? (<div>
            <img src={twitterUser.twitter_photo} alt={twitterUser.twitter_username}/>
            <h1>{twitterUser.twitter_username}</h1>
        </div>) : (<button onClick={handleTwitterLogin}>Log in with Twitter</button>)}
        {discordUser ? (<div>
            <img src={discordUser.discord_avatar} alt={discordUser.discord_username}/>
            <h1>{discordUser.discord_username}</h1>
        </div>) : (<button onClick={handleDiscordLogin}>Log in with Discord</button>)}
        {wallet && wallet.address ? (<div>
            <h1>address: {wallet.address}</h1>
        </div>) : (<button onClick={handleWalletClick}>connect wallet</button>)}

    </div>);
}

export default App;